import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3475043878/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Guide to migrate to v2 of the iOS SDK for Loyalty applications`}</h1>
    <p>{`This is a guide to help you migrate from version 1.x.x of the Fidel API iOS SDK to version 2.0.0.`}</p>
    <h2>{`Update your Fidel API iOS SDK integration`}</h2>
    <h3>{`If you used Cocoapods to integrate iOS SDK`}</h3>
    <p>{`Update to v2 of the iOS SDK by running the following Cocoapods command, from the root folder of your Xcode project.`}</p>
    <pre><code parentName="pre" {...{}}>{`pod update Fidel
`}</code></pre>
    <p>{`You should see in the log the version to which the iOS SDK is updated to.`}</p>
    <h3>{`If you manually integrated the iOS SDK`}</h3>
    <ol>
      <li parentName="ol">{`Download the latest `}<inlineCode parentName="li">{`Fidel.xcframework`}</inlineCode>{` and `}<inlineCode parentName="li">{`analytics.xcframework`}</inlineCode>{` artifacts from the `}<a parentName="li" {...{
          "href": "https://github.com/Enigmatic-Smile/fidel-ios"
        }}>{`iOS SDK GitHub repo`}</a>{`.`}</li>
      <li parentName="ol">{`Replace your existing `}<inlineCode parentName="li">{`Fidel.xcframework`}</inlineCode>{` with the one you downloaded.`}</li>
      <li parentName="ol">{`Add the `}<inlineCode parentName="li">{`analytics.xcframework`}</inlineCode>{` to your project.`}</li>
    </ol>
    <h2>{`Check the following code as your migration guide`}</h2>
    <p>{`Use the following code as a guide related to the changes that to integrate with the Fidel API SDK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {

    var window: UIWindow?

    func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
        // Override point for customization after application launch.
        configureFidel()
        return true
    }
    
    private func configureFidel() {
        // We recommend storing the SDK key on your server. Please use one of your endpoints to 
        // retrieve it and then configure the sdkKey property with it.
        Fidel.sdkKey = yourSDKKey //was apiKey
        Fidel.programID = "Your program ID" //was programId
        Fidel.bannerImage = UIImage(named: "fdl_test_banner")
        // Remove Fidel.autoScan
        Fidel.metaData = [
            "id": "this-is-the-metadata-id",
            "myUserId": 123,
            "myUserId2": 123.3,
            "myUserId3": [12, nil],
            "myUserId4": ["subkey": 12.2, "subkey2": true],
            "customKey1": "customValue1"
        ]
        Fidel.allowedCountries = [.canada, .unitedStates]
        Fidel.companyName = "Cashback Inc."
        Fidel.privacyPolicyURL = "https://www.fidel.uk/" // was privacyURL
        Fidel.termsAndConditionsURL = "https://fidel.uk/docs/" //was termsConditionsURL
        Fidel.deleteInstructions = "contacting our support team"
        Fidel.supportedCardSchemes = [.visa, .mastercard, .americanExpress]
        Fidel.onResult = self.onResult // result callback
    }
    
    func onResult(_ result: FidelResult) {
        switch result {
        case .enrollmentResult(let enrollmentResult):
            print(enrollmentResult.cardID)
        case .error(let fidelError):
            switch fidelError.type {
            case .enrollmentError(let enrollmentError):
                switch enrollmentError {
                case .cardAlreadyExists:
                    print("card was already enrolled")
                default:
                    print("other enrollment error")
                }
            case .sdkConfigurationError:
                print("the SDK should be provided with all the information")
            case .userCanceled:
                print("the user cancelled the flow")
        case .deviceNotSecure:
            print("the device you’re using is not secure")
            @unknown default:
                print("unknown error")
            }
        @unknown default:
            print("unknown result")
        }
    }
}
`}</code></pre>
    <h2>{`Starting the card-linking flow`}</h2>
    <p>{`On any action that you want (on a tap of a button, for example), add the following code: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Fidel.start(from: yourViewController)
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`Fidel.start`}</inlineCode>{` method replaces the previous: `}<inlineCode parentName="p">{`Fidel.present`}</inlineCode>{` method. To get notified about card enrollment events, you ca use the `}<inlineCode parentName="p">{`Fidel.onResult`}</inlineCode>{` callback. Please check our v2 Reference documentation for more details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      