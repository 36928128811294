import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3475043878/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Web SDK `}<a style={{
        "borderBottom": "2px solid #0048ff"
      }} className="improve-docs" href="/select/sdks/web/v2">{`v2`}</a>{` `}<a style={{
        "marginRight": "auto",
        "color": "#111"
      }} className="improve-docs" href="/select/sdks/web/v3">{`v3`}</a></h1>
    <p>{`The Fidel SDKs provide a secure way for you to add card-linking capabilities to your applications. The alternative involves using the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-card"
      }}>{`Fidel Cards API`}</a>{`, but you'll need to be PCI Compliant before you can use it. Using the Fidel SDKs, card details are sent directly to the Fidel API through a secure connection without exposing your servers to sensitive information. They take care of all PCI Compliance requirements, so you don't have to.`}</p>
    <p><strong parentName="p">{`The Fidel Web SDK v2`}</strong>{` is a JavaScript-based SDK that injects a secure HTML iframe into your web page. Your users will need to have JavaScript enabled in their browsers.`}</p>
    <p>{`The SDK provides a pre-built UI that allows you to easily collect credit card details, tokenise and link credit/debit cards with rewards services from your website, e-commerce platform or mobile web apps. However, for mobile apps, we recommend using the `}<a parentName="p" {...{
        "href": "/select/sdks/ios/guide-v2"
      }}>{`iOS`}</a>{`, `}<a parentName="p" {...{
        "href": "/select/sdks/android/guide-v2"
      }}>{`Android`}</a>{` or `}<a parentName="p" {...{
        "href": "/select/sdks/react-native/guide-v2"
      }}>{`React Native`}</a>{` SDKs.`}</p>
    <p>{`You can customize the UI of the SDK to match your use case. All modern desktop and mobile browsers are supported, including Chrome, Firefox, Safari, Microsoft IE and Edge.`}</p>
    <img src="https://docs.fidel.uk/assets/images/sdk_web.png" srcSet="https://docs.fidel.uk/assets/images/sdk_web.png, https://docs.fidel.uk/assets/images/sdk_web@2x.png 2x" alt="Preview of the web SDK" />
    <p>{`After successfully tokenising and linking a card on the Visa, Mastercard or American Express networks, the Fidel API returns the created Card object. The Card object has a unique `}<inlineCode parentName="p">{`id`}</inlineCode>{` that you can use to link each unique card and transaction to your user's account. The `}<inlineCode parentName="p">{`id`}</inlineCode>{` of each linked card is present on the Transaction object as well, as `}<inlineCode parentName="p">{`cardId`}</inlineCode>{`. You can create card-linked web and mobile applications with online and offline transaction data visibility in a matter of minutes.`}</p>
    <h2>{`Integrating the Web SDK`}</h2>
    <p>{`Integrating the Fidel Web SDK into your web application starts with loading the JavaScript file we provide, `}<inlineCode parentName="p">{`https://resources.fidel.uk/sdk/js/v2/fidel.js`}</inlineCode>{`, in a `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tag. It uses the `}<inlineCode parentName="p">{`data-`}</inlineCode>{` attributes to allow you to configure it. You can see an example implementation for integrating the Fidel WebSDK in our `}<a parentName="p" {...{
        "href": "https://github.com/Enigmatic-Smile/fidel-api-sample-app/blob/main/src/components/CreateTransaction.js#L11-L30"
      }}>{`React sample application on GitHub`}</a>{`.`}</p>
    <h3>{`Fidel Web SDK `}<inlineCode parentName="h3">{`<script>`}</inlineCode>{` Configuration`}</h3>
    <p>{`You can configure the Fidel Web SDK by defining attribute parameters on the `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tag in your HTML. All of the parameters follow a naming convention and begin with `}<inlineCode parentName="p">{`data-`}</inlineCode>{`. `}<inlineCode parentName="p">{`data-program-id`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-key`}</inlineCode>{` and `}<inlineCode parentName="p">{`data-company-name`}</inlineCode>{` are the required parameters.`}</p>
    <p>{`The Fidel Web SDK can be customized to better fit your website. You can provide a button title, title, subtitle and logo by using the properties `}<inlineCode parentName="p">{`data-button-title`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-title`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-subtitle`}</inlineCode>{` and `}<inlineCode parentName="p">{`data-logo`}</inlineCode>{`. You can customize CSS colors by using `}<inlineCode parentName="p">{`data-background-color`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-button-color`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-button-title-color`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-subtitle-color`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-terms-color`}</inlineCode>{` and `}<inlineCode parentName="p">{`data-title-color`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`fileName:index.html
<script
  type="text/javascript"
  src="https://resources.fidel.uk/sdk/js/v2/fidel.js"
  class="fidel-form"
  data-company-name="Your company"
  data-key="pk_test_demo"
  data-program-id="bca59bd9-171b-4d1f-92af-4b2b7305268a"
  data-callback="callback"
  data-country-code="GBR"
  data-metadata="metadata"
  data-auto-open="false"
  data-auto-close="false"
  data-allowed-countries="GBR,IRL"
  data-background-color="#ffffff"
  data-button-color="#4dadea"
  data-button-title="Link Card"
  data-button-title-color="#ffffff"
  data-lang="en"
  data-logo="https://yourcompany.com/logo.png"
  data-subtitle="Earn 1 point for every £1 spent online or in-store"
  data-subtitle-color="#000000"
  data-privacy-url="https://yourcompany.com/privacy"
  data-delete-instructions="tapping remove in your settings page."
  data-terms-color="#000000"
  data-title="Link Card"
  data-title-color="#000000"
></script>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`You can continue using the old v1 Web SDK by setting the script `}<inlineCode parentName="p">{`src`}</inlineCode>{` to `}<inlineCode parentName="p">{`https://resources.fidel.uk/sdk/js/v1/fidel.js`}</inlineCode>{`.`}</p>
    </blockquote>
    <h4>{`Fidel Web SDK Parameters`}</h4>
    <dl>
    <div>
        <dt>
            <span><code>data-company-name</code></span>
		<strong>required</strong>
        </dt>
        <dd>the name of the company using card-linking. Maximum length is 35 characters</dd>
    </div>
    <div>
        <dt>
            <span><code>data-key</code></span>
            <strong>required</strong>
        </dt>
        <dd>a valid SDK Key. You can find yours in the <a href="https://dashboard.fidel.uk/account/plan">Fidel Dashboard</a>. It starts with `pk_`.</dd>
    </div>
    <div>
        <dt>
            <span><code>data-program-id</code></span>
            <strong>required</strong>
        </dt>
        <dd>the id of the Fidel Program to link the card to.</dd>
    </div>
    <div>
        <dt>
            <span><code>data-callback</code></span>
        </dt>
        <dd>name of the global callback function</dd>
    </div>
    <div>
        <dt>
            <span><code>data-country-code</code></span>
            <em>values: GBR, IRL, USA, SWE</em>
        </dt>
        <dd>sets a country of issue for all Cards collected and removes the country select box from the UI.</dd>
    </div>
    <div>
        <dt>
            <span><code>data-metadata</code></span>
        </dt>
        <dd>name of the global metadata object</dd>
    </div>
    <div>
        <dt>
            <span><code>data-auto-open</code></span>
            <em>default: false</em>
        </dt>
        <dd>toggles the web form auto-open behaviour on page load</dd>
    </div>
    <div>
        <dt>
            <span><code>data-auto-close</code></span>
            <em>default: true</em>
        </dt>
        <dd>toggles the web form auto-close behaviour on successful card linking</dd>
    </div>
    <div>
        <dt>
            <span><code>data-allowed-countries</code></span>
            <em>default: all supported countries</em>
        </dt>
        <dd>comma separated list of countries that will be shown in the UI dropdown</dd>
    </div>
    <div>
        <dt>
            <span><code>data-background-color</code></span>
        </dt>
        <dd>CSS color code of the form background</dd>
    </div>
    <div>
        <dt>
            <span><code>data-button-color</code></span>
        </dt>
        <dd>CSS color code of the button background</dd>
    </div>
    <div>
        <dt>
            <span><code>data-button-title</code></span>
        </dt>
        <dd>the button title. Maximum length 35 characters</dd>
    </div>
    <div>
        <dt>
            <span><code>data-button-title-color</code></span>
        </dt>
        <dd>CSS color code of the button title</dd>
    </div>
    <div>
        <dt>
            <span><code>data-close-events</code></span>
            <em>default: true</em>
        </dt>
        <dd>adds/removes the close button, overlay click and escape key events from the form</dd>
    </div>
    <div>
        <dt>
            <span><code>data-lang</code></span>
            <em>default: en</em>
        </dt>
        <dd>the localization language to be used. The default language is en (English using British English spellings). Additional languages: en-us (English with US spelling), sv (Swedish), fr (French)</dd>
    </div>
    <div>
        <dt>
            <span><code>data-logo</code></span>
        </dt>
        <dd>the logo URL of the company. Height is 35px. Extensions allowed are jpg, jpeg, png</dd>
    </div>
    <div>
        <dt>
            <span><code>data-subtitle</code></span>
        </dt>
        <dd>the subtitle of the web form. Maximum length 110 characters</dd>
    </div>
    <div>
        <dt>
            <span><code>data-subtitle-color</code></span>
        </dt>
        <dd>CSS color code of the subtitle</dd>
    </div>
    <div>
        <dt>
            <span><code>data-privacy-url</code></span>
        </dt>
        <dd>URL of your company's privacy policy</dd>
    </div>
    <div>
        <dt>
            <span><code>data-delete-instructions</code></span>
        </dt>
        <dd>user instructions to unlink card in your application</dd>
    </div>
    <div>
        <dt>
            <span><code>data-terms-color</code></span>
        </dt>
        <dd>CSS color code of the terms and conditions</dd>
    </div>
    <div>
        <dt>
            <span><code>data-title</code></span>
        </dt>
        <dd>the title of the web form. Maximum length 25 characters</dd>
    </div>
    <div>
        <dt>
            <span><code>data-title-color</code></span>
        </dt>
        <dd>CSS color code of the title</dd>
    </div>
    <div>
        <dt>
            <span><code>data-scheme-*</code></span>
            <em>amex / visa / mastercard: <code>false</code></em>
        </dt>
        <dd>disables Amex, Visa or Mastercard. Multiple can be specified, e.g. <code>data-scheme-mastercard: false</code>, <code>data-scheme-amex: false</code></dd>
    </div>
    </dl>
    <p>{`The `}<inlineCode parentName="p">{`data-auto-open`}</inlineCode>{` property allows you to open the web form automatically on page load if set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. If `}<inlineCode parentName="p">{`data-auto-close`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`false`}</inlineCode>{`, the form won't be automatically closed after linking a card. You can set `}<inlineCode parentName="p">{`data-close-events`}</inlineCode>{` to `}<inlineCode parentName="p">{`false`}</inlineCode>{`, and the form won't add the default close events.`}</p>
    <h4>{`Fidel Web SDK Global Objects`}</h4>
    <p>{`After adding the Web SDK script on your website, a global variable `}<inlineCode parentName="p">{`Fidel`}</inlineCode>{` is created with two methods that you can use to open and close the web form manually, `}<inlineCode parentName="p">{`Fidel.openForm()`}</inlineCode>{` and `}<inlineCode parentName="p">{`Fidel.closeForm()`}</inlineCode>{`.`}</p>
    <p>{`To open the iframe overlay form with a button, you could use this example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button type="submit" onclick="Fidel.openForm()">Link Card</button>
`}</code></pre>
    <h5>{`Web SDK callback`}</h5>
    <p>{`To receive a callback after the form submission, you must register a Javascript global function name reference on the `}<inlineCode parentName="p">{`data-callback`}</inlineCode>{` property of the SDK. It will be called with the `}<inlineCode parentName="p">{`response`}</inlineCode>{` and `}<inlineCode parentName="p">{`error`}</inlineCode>{` objects after the form submits. Please see an example below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`fileName:index.html
<script>
  function callback(error, card) {
    console.log("Card Link Error", error);
    console.log("Card Linked Successfully", card);
  }
</script>
`}</code></pre>
    <h5>{`Web SDK metadata`}</h5>
    <p>{`To store custom data related to a Card, you can pass a Javascript global object name reference on the `}<inlineCode parentName="p">{`data-metadata`}</inlineCode>{` property. The metadata `}<inlineCode parentName="p">{`id`}</inlineCode>{` property is a `}<em parentName="p">{`non-unique index`}</em>{`, so you can set it to a custom UID (unique identifier). Later you can query the cards using the same `}<inlineCode parentName="p">{`metadata.id`}</inlineCode>{`. You can use our `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/v1/reference/list-cards-from-metadata-id"
      }}>{`"List Cards from Metadata ID"`}</a>{` API Endpoint.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Hint: Adding user data in the metadata as key: value pairs can simplify reconciliation with your system. For example, adding `}<inlineCode parentName="p">{`'myUserId':'123'`}</inlineCode>{` will help you match the added card to your user with id 123.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`fileName:index.html
<script>
  var metadata = {
    id: "this-is-the-metadata-id",
    myUserId: "123",
    customKey: "customValue",
  };
</script>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      